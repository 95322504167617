const links = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'About',
    url: '/about/',
  },
];

export default links;
